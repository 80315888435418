import React from "react";

import { useStore } from "../../../../../../context/state";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import Link from "next/link";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {
  prettyDateUSA
} from "commons-ui/core/utils/date";

export default function CityDialog({ city, events, onClose, open }) {
  const { isMobile } = useStore();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      scroll="paper"
    >
      <div className="u-flexCenter u-justifyContentSpaceBetween">
        <div className="u-fontWeightBold u-fontSize18 u-paddingLeft24 u-paddingVertical5">Active Events in <span className="u-textColorPrimary u-fontWeightBold">{city}</span></div>
        <div>
          <Tooltip title="Close">
            <IconButton className="u-marginRight8" onClick={onClose}>
              <Close />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <DialogContent dividers>
        <div>
                  {events.map((v, i) => {
              const itemId = "ecommerce_store_delivery_option_event_id"
            const itemIdValue = v[itemId];
            const slug = v.slug;
            const startAt = new Date(v.start_at);
            startAt.setHours(0, 1);
            const endAt = new Date(v.end_at);
            endAt.setHours(23, 59);
            const now = new Date();

            const cutoffAt = v.cutoff_at ? new Date(v.cutoff_at) : undefined;

            const isPostponed = v.is_postponed;
            const isSoldOut =
              v.is_sold_out ||
              (cutoffAt ? now.getTime() > cutoffAt.getTime() : false);

            return (
              <div key={i} className="u-flexCenter u-marginBottom20 u-justifyContentSpaceBetween u-xs-flexColumn">
                <div className="u-flexCenter">
                  <span>{v.name}</span>
                </div>
                <div className="u-xs-sizeFullWidth u-marginLeft10 u-flexCenter u-xs-justifyContentSpaceBetween u-xs-marginLeft0 u-xs-marginTop10">
                {
                    startAt ?
                      <span className="u-marginRight10 u-border1 u-borderRadius10 u-paddingHorizontal10 u-paddingVertical3 u-borderColorLighter u-marginTop1">
                        {prettyDateUSA(startAt)}
                      </span>
                      :
                      null
                  }

                  {isSoldOut || isPostponed ? (
                    <div className="u-textColorRed u-fontWeightBold u-minWidth120 u-textAlignCenter">
                      {isSoldOut ? "Sold Out" : "Postponed"}
                    </div>
                  ) : (
                    <Link href={"/events/" + (slug || itemIdValue)}>
                      <Button
                                        className="button-secondary u-minWidth120"
                                        size="small"
                        variant="outlined"
                      >
                        Pre-Order
                      </Button>
                    </Link>
                  )}
                  
                </div>
              </div>
            );
          })}
        </div>
      </DialogContent>
    </Dialog>
  );
};
