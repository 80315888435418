import React, { useEffect, useState } from "react";

import Search from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";

import "./SearchWidget.module.scss";

export const SearchWidget = (props) => {
  const { value, onSearch } = props;
  const [search, setSearch] = useState(value);

  const onChange = (value) => {
    props.onChange ? props.onChange(value) : null;
    setSearch(value);
  };

  useEffect(() => {
    onChange(value);
  }, [value]);

  return (
    <div className="event-search-widget u-height44 u-flexCenter u-relative u-justifyContentSpaceBetween u-paddingVertical3 u-paddingRight5 u-paddingLeft10">
      <InputBase
        onChange={(event) => onChange(event.target.value)}
        value={search}
        placeholder="Search..."
        className="u-sizeFullWidth"
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            onSearch(search);
          }
        }}
      />
      <IconButton
        className="u-padding3 u-opacity80 u-hide"
        onClick={() => onSearch(search)}
      >
        <Search className="u-opacity50" />
      </IconButton>
    </div>
  );
};
