import React, { useState, useEffect, useMemo } from "react";
import Calendar from "react-calendar";

import { SearchWidget } from "./SearchWidget";
import { DeliveryCostPart } from "../../../DeliveryCostPart";
import { useStore } from "../../../../context/state";
import AddToCalendarButton from "../../../AddToCalendarButton";
import Link from "next/link";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";

import {
  prettyDateUSA,
  dateToTimeAMPM,
  dateToISODate,
  addZeroToDate
} from "commons-ui/core/utils/date";
import Loading from "commons-ui/core/Loading";
import { saveToLS } from "commons-ui/core/utils/index";
import "react-calendar/dist/Calendar.css";
import "./EventList.module.scss";
import MapChart from "./MapChart";
import {dataSourceV2} from "commons-ui/core/DataSource";

import TrackImage from "../../../../images/img/track.png";

const initDate = () => {
  let now = new Date();
  now.setHours(0, 2);
  return now;
};

export const EventForm = (props) => {
  const { itemIdParent, itemIdValueParent, itemId, modelName, readOnly } = props;

  const { isMobile, dispatch, apiPath } = useStore();

  const [loading, setLoading] = useState(false);
  const [dateDict, setDateDict] = useState(undefined);
  const [items, setItems] = useState([]);
  const [selected, setSelected] = useState("");
  const [search, setSearch] = useState("");
  const [dateSelected, setDateSelected] = useState(initDate());


  const request = () => {
      setLoading(true);
      const date = `${dateSelected.getFullYear()}-${addZeroToDate(dateSelected.getMonth() + 1)}-${addZeroToDate(dateSelected.getDate())}`;
      dataSourceV2(`${modelName}_list?is_future=true&with_max_orders1=true&${itemIdParent}=${itemIdValueParent}`, {url: apiPath})
          .then(v=> {
              if (!v.message && v.item_list) {
                  const now = new Date();
                  const dateDict = {};
                  const items = v.item_list.filter((v, i) => {
                      if (v.is_sold_out || v.is_postponed) {
                          //return false;
                      }
                      if (v.cutoff_at) {
                          const cutoffAt = new Date(v.cutoff_at);
                          if (now > cutoffAt) {
                              //return false;
                          }
                      }
                      if (v.start_at) {
                          let startAt = new Date(v.start_at);
                          startAt = new Date(startAt.getTime() + 16*60*60000);

                          if (now > startAt) {
                              return false;
                          }
                      }
                      dateDict[dateToISODate(new Date(v.start_at))] = true;
                      dateDict[dateToISODate(new Date(v.end_at))] = true;
                      return true;
                  });
                  setDateDict(dateDict);
                  items.sort((a,b) => (a.start_at > b.start_at) ? 1 : ((b.start_at > a.start_at) ? -1 : 0));
                  setItems(items);

              }
              setLoading(false);
          });
  };


  useEffect(() => {
      if (itemIdValueParent) request();
  }, [itemIdValueParent, dateSelected]);

  let titleDict = {};

  items.forEach((v, i) => {
    if (!v.start_at || !v.end_at) return null;

    const prettyDate = prettyDateUSA(new Date(v.start_at));
    if (titleDict[prettyDate] === undefined) {
      titleDict[prettyDate] = i;
    }
  });

  const refs = Object.keys(titleDict)
    .sort()
    .reduce((acc, value) => {
      acc[value] = React.createRef();
      return acc;
    }, {});

  const onScroll = (id) => {
    if (!refs[id] || !refs[id].current) {
      return;
    }
    refs[id].current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const itemsFiltered = useMemo(() => {
    if (search) {
      return items.filter((v) =>
        (
          (v.city || "") +
          (v.state || "") +
          (v.address || "") +
          (v.postal_code || "") +
          (v.name || "")
        )
          .toLowerCase()
          .includes(search.toLowerCase())
      );
    }
    return items;
  }, [items.length, search, selected, dateSelected]);

  return (
    <>
      <div className="event-delivery-form u-flexColumn u-marginTop0 u-xs-marginTop20">
        <div className="calendar-widget u-marginTop25 u-xs-marginTop0 u-marginBottom25 u-flexCenter u-justifyContentSpaceBetween u-sizeFullWidth u-paddingLeft80 u-paddingRight40 u-xs-paddingHorizontal24 u-xs-flexColumn">
          <div className="u-sizeFullWidth">
            <span className="u-flex u-textColorNormal u-fontWeightBold u-fontSize18 u-xs-hide">
              Search By City Name
            </span>
            <div className="u-marginBottom20">
              <SearchWidget
                {...props}
                value={search}
                onChange={(value) => setSearch(value)}
                onSearch={() => null}
              />
            </div>
            <span className="u-flex  u-textColorNormal u-fontWeightBold u-fontSize18 u-xs-hide">
              Search By Date
            </span>

            {dateDict ? (
              <Calendar
                onChange={(d) => {
                  if (d) {
                    //d.setHours(0, 2);
                  }
                  //setDateSelected(d)
                  if (search) {
                    setSearch("");
                    setTimeout(() => onScroll(prettyDateUSA(d)), 1000);
                  } else {
                    onScroll(prettyDateUSA(d));
                  }
                }}
                value={dateSelected}
                next2Label={null}
                prev2Label={null}
                calendarType={"US"}
                locale={"en-US"}
                tileDisabled={({ date }) => {
                  return !dateDict[dateToISODate(date)];
                }}
                minDate={new Date()}
              />
            ) : null}

          </div>

          <div className="u-sizeFullWidth u-flexCenter u-justifyContentCenter u-xs-marginTop30 u-xs-marginBottom10" style={{height: 200}}>
            {/*<img src={TrackImage} />*/}
            {
              dateDict ?
                <MapChart events={itemsFiltered}/>
                :
                null
            }
          </div>
        </div>
        <div className="u-textColorNormal u-paddingHorizontal20 u-maxWidth750">
          Thank you for ordering with us. Quick notes to take into account:
          <br/>
          <br/>
          <li> Make sure to check for a confirmation email after your purchase. </li>
          <li>Please pick up during the allotted time unless advised, otherwise with either the name the order is placed under or the confirmation number.</li> 
          <li>Please contact us with any questions or concerns at (206) 764-1000 or CS@PiroshkyBakery.com </li>
          <li>Your order is baked, frozen, and packed in an insulated box with ice packs. To ensure product quality, we strive to keep products under 40 degrees at the beginning time of the pick-up window. </li>
          <li>All orders must be picked up within the Pre-Order pick-up time, Piroshky Piroshky cannot leave the liability of your product with our host.</li>
          <li>Our Drivers and Hosts do not Carry Cash. </li>   
        </div>
        
        <div className="u-sizeFullWidth u-paddingBottom100">
          {loading ? (
            <Loading className="u-marginTop20 u-marginBottom20" />
          ) : itemsFiltered.length ? (
            <List className="">
              {itemsFiltered.map((v, i) => {
                  const itemIdValue = v[itemId];
                  const slug = v.slug;
                if (readOnly && v[itemId] !== selected) {
                  return null;
                }
                const selectedLocal = v[itemId] === selected;
                const startAt = new Date(v.start_at);
                startAt.setHours(0, 1);
                const endAt = new Date(v.end_at);
                endAt.setHours(23, 59);
                const now = new Date();

                const cutoffAt = v.cutoff_at
                  ? new Date(v.cutoff_at)
                  : undefined;

                const isPostponed = v.is_postponed;
                const isSoldOut = v.is_sold_out || (cutoffAt ? now.getTime() > cutoffAt.getTime() : false);

                let date = prettyDateUSA(startAt);
                if (startAt.getTime() !== endAt.getTime()) {
                  date += " - " + prettyDateUSA(endAt);
                }

                const title = prettyDateUSA(startAt);
                const showDate = titleDict[title] === i;

                return (
                  <span key={i}>
                    {showDate ? (
                      <span
                        id={title}
                        ref={refs[title]}
                        className="u-flexCenter u-justifyContentCenter u-marginBottom10 u-xs-marginBottom0 u-marginTop20 u-fontSize18 u-textColorNormal u-fontWeightBold"
                      >
                        <span>{title}</span>
                      </span>
                    ) : null}
                    <ListItem
                      key={i}
                      className="event-item u-paddingLeft80 u-paddingRight80 u-xs-paddingHorizontal24 u-textColorNormal u-fontSize12"
                    >
                      <div className="u-sizeFullWidth u-flex u-justifyContentSpaceBetween u-xs-flexColumn">
                        <div className="u-sizeFullWidth u-maxWidth400">
                            <div className="u-textColorDark u-fontWeightBold u-fontSize15">
                              {v.name}
                            </div>
                            <div className="u-marginTop8">
                              <AddToCalendarButton className="button-grey" size="small" event={v}/>
                            </div>
                          <div className="u-marginTop10">
                            <div className="u-textColorDarker u-marginBottom2">
                              Pick Up Address:
                            </div>
                            <div>
                              {v.address}
                              {v.city ? ", " + v.city : ""}
                              {v.state ? ", " + v.state : ""}
                              {v.postal_code ? ", " + v.postal_code : ""}
                            </div>
                          </div>
                          {v.description ? (
                            <div className="u-marginTop5">
                              <div className="u-textColorDarker u-marginBottom2">
                                Description:
                              </div>
                              <div>{v.description}</div>
                            </div>
                          ) : null}
                        </div>
                        <div
                          className={
                            "u-sizeFullWidth u-flexColumn u-justifyContentStart u-xs-marginTop20 " +
                            (isMobile ? "" : "u-alignItemsFlexEnd")
                          }
                        >
                          <div className="u-marginTop5 u-textColorDarker">
                            {date ? (
                              <span className="">
                                Event Date:{" "}
                                <span className="u-textColorNormal">
                                  {date}
                                </span>
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                          {cutoffAt ? (
                            <div className="u-marginTop5 u-textColorDarker">
                              <span className="">
                                Cutoff At:{" "}
                                <span className="u-textColorNormal">
                                  {prettyDateUSA(cutoffAt)}{" "}
                                  {dateToTimeAMPM(cutoffAt)}
                                </span>
                              </span>
                            </div>
                          ) : null}
                          {v.min_order ? (
                            <div className="u-marginTop5 u-textColorDarker">
                              <Tooltip
                                title={
                                  "Min. order of in-house items before tax"
                                }
                              >
                                <div className="">
                                  Min. order:{" "}
                                  <span className="u-textColorNormal">
                                    ${v.min_order}
                                  </span>
                                </div>
                              </Tooltip>
                            </div>
                          ) : null}
                          <div className="u-flex">
                            <div className="u-marginTop10 u-marginBottom5 u-flexColumn u-border1 u-borderColorLightest u-borderRadius10 u-padding5 u-paddingHorizontal10">
                              <span className="u-textColorDarker u-marginBottom5">Delivery Cost:</span>
                              <DeliveryCostPart defaultCost={v.cost} 
                                                costJson={v.delivery_cost_json} 
                                                itemClassName="u-textAlignRight u-fontSize10"/>
                            </div>
                          </div>
                          

                          <div className="u-marginTop10">
                            {isSoldOut || isPostponed ? (
                              <div className="u-textColorRed u-fontWeightBold">
                                {isSoldOut ? "Sold Out" : "Postponed"}
                              </div>
                            ) : (
                                <Link href={"/events/" + (slug || itemIdValue)}>
                                    <Button
                                      className="button-secondary u-minWidth160 u-height40"
                                      variant="outlined"
                                    >
                                      Pre-Order
                                    </Button>
                                </Link>
                            )}
                          </div>
                        </div>
                      </div>
                    </ListItem>
                  </span>
                );
              })}
            </List>
          ) : (
            <div className="u-marginTop15 u-textColorNormal u-paddingHorizontal24">
              No Events. Select another date
            </div>
          )}
        </div>
      </div>
    </>
  );
};
