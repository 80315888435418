export const weekdayMapping = {
    0 : "Воскресенье",
    1 : "Понедельник",
    2 : "Вторник",
    3 : "Среда",
    4 : "Четверг",
    5 : "Пятница",
    6 : "Суббота"
};


export const monthMapping = {
    0 : "January",
    1 : "February",
    2 : "March",
    3 : "April",
    4 : "May",
    5 : "June",
    6 : "July",
    7 : "August",
    8 : "September",
    9 : "October",
    10 : "November",
    11 : "December"
};


export const monthShortMapping = {
    0 : "January",
    1 : "February",
    2 : "March",
    3 : "April",
    4 : "May",
    5 : "June",
    6 : "July",
    7 : "August",
    8 : "September",
    9 : "October",
    10 : "November",
    11 : "December"
};


export const dateToLocalIsoString = (d) => {
    const time = d.toLocaleTimeString();
    const date = d.toLocaleDateString();
    const r = `${s.slice(6, 10)}-${s.slice(3, 5)}-${s.slice(0, 2)}T${time}.000Z`;
};

export const dateToIsoTZ = (d) => {
    var tzo = -d.getTimezoneOffset(),
        dif = tzo >= 0 ? "+" : "-",
        pad = function(num) {
            var norm = Math.floor(Math.abs(num));
            return (norm < 10 ? "0" : "") + norm;
        };
    return d.getFullYear() +
        "-" + pad(d.getMonth() + 1) +
        "-" + pad(d.getDate()) +
        "T" + pad(d.getHours()) +
        ":" + pad(d.getMinutes()) +
        ":" + pad(d.getSeconds()) +
        dif + pad(tzo / 60) +
        ":" + pad(tzo % 60);
};

export const dateToLocal = (d) => {
    if (typeof d === "string") {
        d = new Date(d);
    }
    const offset = d.getTimezoneOffset();
    const offsetLocal = new Date().getTimezoneOffset();
    d.setMinutes( d.getMinutes() - (offsetLocal - offset) );
    return d;
};

export const addZeroToDate = (d) => d.toString().length === 1 ? "0" + d : d;

export const dateToTime = (d) => `${addZeroToDate(d.getHours())}:${addZeroToDate(d.getMinutes())}`;

export const dateToTimeAMPM = (d) => formatAMPM(d);

const formatAMPM = (date) => {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0"+minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};


export const timeStringToDate = (t) => new Date((new Date()).toISOString().split("T")[0] + "T" + t + ".000Z");

export const dateToISODate = (d) => `${d.getFullYear()}-${addZeroToDate(d.getMonth() + 1)}-${addZeroToDate(d.getDate())}`;

export const dateToFullTime = (d) => `${addZeroToDate(d.getHours())}:${addZeroToDate(d.getMinutes())}:${addZeroToDate(d.getSeconds())}`;

export const dateToShortDate = (d) => `${monthShortMapping[d.getMonth()]} ${d.getDay()}`;

export const prettyDateStandart = (d) => d ? `${addZeroToDate(d.getDate())}.${addZeroToDate(d.getMonth() + 1)}.${d.getFullYear()}` : "";

export const prettyDateUSA = (d) => d ? `${addZeroToDate(d.getMonth() + 1)}/${addZeroToDate(d.getDate())}/${d.getFullYear()}` : "";

export const prettyDateTimeStandart = (d) => d ? `${addZeroToDate(d.getDate())}.${addZeroToDate(d.getMonth() + 1)}.${d.getFullYear() + " " + dateToTime(d)}` : "";

export const prettyDateTimeFull = (d) => d ? `${addZeroToDate(d.getDate())}.${addZeroToDate(d.getMonth() + 1)}.${d.getFullYear() + " " + dateToFullTime(d)}` : "";

export const prettyDateNoYear = (d) => d ? `${addZeroToDate(d.getDate())}.${addZeroToDate(d.getMonth() + 1)}` : "";

export const prettyDateTimeUSA = (d) => d ? (prettyDateUSA(d) + " " + dateToFullTime(d)) : "";

export const prettyDateTimeAMPMUSA = (d) => d ? (prettyDateUSA(d) + " " + dateToTimeAMPM(d)) : "";

export const prettyDate = (dateStr) => {
    if (!dateStr){
        return;
    }
    const d = new Date(dateStr);
    const now = new Date();
    if (now.toDateString() === d.toDateString()){
        return dateToTime(d);
    } else if (d.getFullYear() === now.getFullYear()){
        return dateToShortDate(d);
    }
    return `${dateToShortDate(d)}, ${d.getFullYear()}`;
};
