import React, {useMemo} from 'react'
import ArrowRight from '@material-ui/icons/ArrowRight';
import "./DeliveryCost.module.scss"



export const DeliveryCostPart = ({defaultCost, costJson, className, itemClassName}) => {
  
  const rules = useMemo(() => {
    const rules = JSON.parse(costJson || "[]")
    return rules
  }, [costJson])

  if (!rules || rules.length === 0) {
    if (!defaultCost) {
      return <>free</>
    }
    return <>${defaultCost}</>
  }

  return (
              
    <div className={className} style={{width:"100%"}}>
        {
          rules.map((rule, i) => {
            const isLast = i === (rules.length - 1)
            const isFree = isLast && !rule.cost
            
            return (
              <span className={"u-flexCenter " + (itemClassName || "")}>
                  {
                    isLast ?
                      <span>from ${rule.amount_min}</span>
                      :
                      <span>${rule.amount_min} - ${rule.amount_max}</span>
                  } 
                  <ArrowRight className="u-fontSize18"/> <span className={"u-fontWeightBold " + (isLast ? "u-textColorGreenDark" : "")}>{isFree ? "FREE" : ("$" + (rule.cost || 0).toString())}</span>
                </span>
            )
          })
        }
    </div> 
  );
};

