import React, {useState} from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import "./style.styl";


export default function PickUpMap(props) {
  const { lat, lng, isMobile } = props;
  const [map, setMap] = useState(null);

  const containerStyle = {
    width: isMobile ? "100%" : "350px",
    height: isMobile ? "180px" : "220px",
    borderRadius: 15,
    border: "1px solid #eee"
  };

  const center = {
    lat: lat,
    lng: lng,
  };

  const onLoad = React.useCallback(function callback(map) {
    map.setCenter(new google.maps.LatLng(47, 116.3974589));
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return (
    <LoadScript googleMapsApiKey="AIzaSyAni95BiWla14z0UKlgF-wrt51hRQueOVc">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={14}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={{disableDefaultUI: true}}
      >
        <Marker onLoad={() => console.log()} position={center} />
      </GoogleMap>
    </LoadScript>
  );
}
