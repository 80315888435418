import React, {useState, useEffect, useMemo} from "react";
import Button from "@material-ui/core/Button";
import Loading from "commons-ui/core/Loading";
import renderHTML from "react-render-html";
import "./style.styl";




export default function RedirectForm(props) {
    const {item, description, itemId, modelName, itemIdParent, itemIdValueParent, errors, touched, onBack, setFieldValue, isSubmitting, apiPath} = props;


    return (
        <div className="u-marginTop20 u-paddingLeft80 u-paddingRight80 u-xs-paddingHorizontal24">
            {
                item.description &&
                    <div className="u-marginBottom20 u-textColorNormal u-fontSize14 u-maxWidth500">
                        {renderHTML(item.description)}
                    </div>
            }
            <a href={item.redirect} target="_blank">
                <Button aria-label="Go To GolBelly Piroshky Store" className="button-secondary u-minWidth160 u-height40" size="large">
                    Go to Goldbelly
                </Button>
            </a>
        </div>
    );
}
