

export const requestedTimeOptions = [
    {"name": "6:00am - 6:15am", "value": "06:00_06:15"},
    {"name": "6:15am - 6:30am", "value": "06:15_06:30"},
    {"name": "6:30am - 6:45am", "value": "06:30_06:45"},
    {"name": "6:45am - 7:00am", "value": "06:45_07:00"},
    {"name": "7:00am - 7:15am", "value": "07:00_07:15"},
    {"name": "7:15am - 7:30am", "value": "07:15_07:30"},
    {"name": "7:30am - 7:45am", "value": "07:30_07:45"},
    {"name": "7:45am - 8:00am", "value": "07:45_08:00"},
    {"name": "8:00am - 8:15am", "value": "08:00_08:15"},
    {"name": "8:15am - 8:30am", "value": "08:15_08:30"},
    {"name": "8:30am - 8:45am", "value": "08:30_08:45"},
    {"name": "8:45am - 9:00am", "value": "08:45_09:00"},
    {"name": "9:00am - 9:15am", "value": "09:00_09:15"},
    {"name": "9:15am - 9:30am", "value": "09:15_09:30"},
    {"name": "9:30am - 9:45am", "value": "09:30_09:45"},
    {"name": "9:45am - 10:00am", "value": "09:45_10:00"},
    {"name": "10:00am - 10:15am", "value": "10:00_10:15"},
    {"name": "10:15am - 10:30am", "value": "10:15_10:30"},
    {"name": "10:30am - 10:45am", "value": "10:30_10:45"},
    {"name": "10:45am - 11:00am", "value": "10:45_11:00"},
    {"name": "11:00am - 11:15am", "value": "11:00_11:15"},
    {"name": "11:15am - 11:30am", "value": "11:15_11:30"},
    {"name": "11:30am - 11:45am", "value": "11:30_11:45"},
    {"name": "11:45am - 12:00pm", "value": "11:45_12:00"},
    {"name": "12:00pm - 12:15pm", "value": "12:00_12:15"},
    {"name": "12:15pm - 12:30pm", "value": "12:15_12:30"},
    {"name": "12:30pm - 12:45pm", "value": "12:30_12:45"},
    {"name": "12:45pm - 1:00pm", "value": "12:45_13:00"},
    {"name": "1:00pm - 1:15pm", "value": "13:00_13:15"},
    {"name": "1:15pm - 1:30pm", "value": "13:15_13:30"},
    {"name": "1:30pm - 1:45pm", "value": "13:30_13:45"},
    {"name": "1:45pm - 2:00pm", "value": "13:45_14:00"},
    {"name": "2:00pm - 2:15pm", "value": "14:00_14:15"},
    {"name": "2:15pm - 2:30pm", "value": "14:15_14:30"},
    {"name": "2:30pm - 2:45pm", "value": "14:30_14:45"},
    {"name": "2:45pm - 3:00pm", "value": "14:45_15:00"},
    {"name": "3:00pm - 3:15pm", "value": "15:00_15:15"},
    {"name": "3:15pm - 3:30pm", "value": "15:15_15:30"},
    {"name": "3:30pm - 3:45pm", "value": "15:30_15:45"},
    {"name": "3:45pm - 4:00pm", "value": "15:45_16:00"},
    {"name": "4:00pm - 4:15pm", "value": "16:00_16:15"},
    {"name": "4:15pm - 4:30pm", "value": "16:15_16:30"},
    {"name": "4:30pm - 4:45pm", "value": "16:30_16:45"},
    {"name": "4:45pm - 5:00pm", "value": "16:45_17:00"},
    {"name": "5:00pm - 5:15pm", "value": "17:00_17:15"},
    {"name": "5:15pm - 5:30pm", "value": "17:15_17:30"},
    {"name": "5:30pm - 5:45pm", "value": "17:30_17:45"},
    {"name": "5:45pm - 6:00pm", "value": "17:45_18:00"},
    {"name": "6:00pm - 6:15pm", "value": "18:00_18:15"},
    {"name": "6:15pm - 6:30pm", "value": "18:15_18:30"},
    {"name": "6:30pm - 6:45pm", "value": "18:30_18:45"},
    {"name": "6:45pm - 7:00pm", "value": "18:45_19:00"},
    {"name": "7:00pm - 7:15pm", "value": "19:00_19:15"},
    {"name": "7:15pm - 7:30pm", "value": "19:15_19:30"},
    {"name": "7:30pm - 7:45pm", "value": "19:30_19:45"},
    {"name": "7:45pm - 8:00pm", "value": "19:45_20:00"},
    {"name": "8:00pm - 8:15pm", "value": "20:00_20:15"},
    {"name": "8:15pm - 8:30pm", "value": "20:15_20:30"},
    {"name": "8:30pm - 8:45pm", "value": "20:30_20:45"},
    {"name": "8:45pm - 9:00pm", "value": "20:45_21:00"},
    {"name": "9:00pm - 9:15pm", "value": "21:00_21:15"},
    {"name": "9:15pm - 9:30pm", "value": "21:15_21:30"},
    {"name": "9:30pm - 9:45pm", "value": "21:30_21:45"},
    {"name": "9:45pm - 10:00pm", "value": "21:45_22:00"},
    {"name": "10:00pm - 10:15pm", "value": "22:00_22:15"},
    {"name": "10:15pm - 10:30pm", "value": "22:15_22:30"},
    {"name": "10:30pm - 10:45pm", "value": "22:30_22:45"},
    {"name": "10:45pm - 11:00pm", "value": "22:45_23:00"}
];

export function getDeliveryRequestedTimeOptions(interval) {
    if (interval === "15") {
        return [
            {"name": "6:00am - 6:15am", "value": "06:00_06:15"},
            {"name": "6:15am - 6:30am", "value": "06:15_06:30"},
            {"name": "6:30am - 6:45am", "value": "06:30_06:45"},
            {"name": "6:45am - 7:00am", "value": "06:45_07:00"},
            {"name": "7:00am - 7:15am", "value": "07:00_07:15"},
            {"name": "7:15am - 7:30am", "value": "07:15_07:30"},
            {"name": "7:30am - 7:45am", "value": "07:30_07:45"},
            {"name": "7:45am - 8:00am", "value": "07:45_08:00"},
            {"name": "8:00am - 8:15am", "value": "08:00_08:15"},
            {"name": "8:15am - 8:30am", "value": "08:15_08:30"},
            {"name": "8:30am - 8:45am", "value": "08:30_08:45"},
            {"name": "8:45am - 9:00am", "value": "08:45_09:00"},
            {"name": "9:00am - 9:15am", "value": "09:00_09:15"},
            {"name": "9:15am - 9:30am", "value": "09:15_09:30"},
            {"name": "9:30am - 9:45am", "value": "09:30_09:45"},
            {"name": "9:45am - 10:00am", "value": "09:45_10:00"},
            {"name": "10:00am - 10:15am", "value": "10:00_10:15"},
            {"name": "10:15am - 10:30am", "value": "10:15_10:30"},
            {"name": "10:30am - 10:45am", "value": "10:30_10:45"},
            {"name": "10:45am - 11:00am", "value": "10:45_11:00"},
            {"name": "11:00am - 11:15am", "value": "11:00_11:15"},
            {"name": "11:15am - 11:30am", "value": "11:15_11:30"},
            {"name": "11:30am - 11:45am", "value": "11:30_11:45"},
            {"name": "11:45am - 12:00pm", "value": "11:45_12:00"},
            {"name": "12:00pm - 12:15pm", "value": "12:00_12:15"},
            {"name": "12:15pm - 12:30pm", "value": "12:15_12:30"},
            {"name": "12:30pm - 12:45pm", "value": "12:30_12:45"},
            {"name": "12:45pm - 1:00pm", "value": "12:45_13:00"},
            {"name": "1:00pm - 1:15pm", "value": "13:00_13:15"},
            {"name": "1:15pm - 1:30pm", "value": "13:15_13:30"},
            {"name": "1:30pm - 1:45pm", "value": "13:30_13:45"},
            {"name": "1:45pm - 2:00pm", "value": "13:45_14:00"},
            {"name": "2:00pm - 2:15pm", "value": "14:00_14:15"},
            {"name": "2:15pm - 2:30pm", "value": "14:15_14:30"},
            {"name": "2:30pm - 2:45pm", "value": "14:30_14:45"},
            {"name": "2:45pm - 3:00pm", "value": "14:45_15:00"},
            {"name": "3:00pm - 3:15pm", "value": "15:00_15:15"},
            {"name": "3:15pm - 3:30pm", "value": "15:15_15:30"},
            {"name": "3:30pm - 3:45pm", "value": "15:30_15:45"},
            {"name": "3:45pm - 4:00pm", "value": "15:45_16:00"},
            {"name": "4:00pm - 4:15pm", "value": "16:00_16:15"},
            {"name": "4:15pm - 4:30pm", "value": "16:15_16:30"},
            {"name": "4:30pm - 4:45pm", "value": "16:30_16:45"},
            {"name": "4:45pm - 5:00pm", "value": "16:45_17:00"},
            {"name": "5:00pm - 5:15pm", "value": "17:00_17:15"},
            {"name": "5:15pm - 5:30pm", "value": "17:15_17:30"},
            {"name": "5:30pm - 5:45pm", "value": "17:30_17:45"},
            {"name": "5:45pm - 6:00pm", "value": "17:45_18:00"},
            {"name": "6:00pm - 6:15pm", "value": "18:00_18:15"},
            {"name": "6:15pm - 6:30pm", "value": "18:15_18:30"},
            {"name": "6:30pm - 6:45pm", "value": "18:30_18:45"},
            {"name": "6:45pm - 7:00pm", "value": "18:45_19:00"},
            {"name": "7:00pm - 7:15pm", "value": "19:00_19:15"},
            {"name": "7:15pm - 7:30pm", "value": "19:15_19:30"},
            {"name": "7:30pm - 7:45pm", "value": "19:30_19:45"},
            {"name": "7:45pm - 8:00pm", "value": "19:45_20:00"},
            {"name": "8:00pm - 8:15pm", "value": "20:00_20:15"},
            {"name": "8:15pm - 8:30pm", "value": "20:15_20:30"},
            {"name": "8:30pm - 8:45pm", "value": "20:30_20:45"},
            {"name": "8:45pm - 9:00pm", "value": "20:45_21:00"},
            {"name": "9:00pm - 9:15pm", "value": "21:00_21:15"},
            {"name": "9:15pm - 9:30pm", "value": "21:15_21:30"},
            {"name": "9:30pm - 9:45pm", "value": "21:30_21:45"},
            {"name": "9:45pm - 10:00pm", "value": "21:45_22:00"},
            {"name": "10:00pm - 10:15pm", "value": "22:00_22:15"},
            {"name": "10:15pm - 10:30pm", "value": "22:15_22:30"},
            {"name": "10:30pm - 10:45pm", "value": "22:30_22:45"},
            {"name": "10:45pm - 11:00pm", "value": "22:45_23:00"}
        ]
    }
    if (interval === "30") {
        return [
            {"name": "6:00am - 6:30am", "value": "06:00_06:30"},
            {"name": "6:30am - 7:00am", "value": "06:30_07:00"},
            {"name": "7:00am - 7:30am", "value": "07:00_07:30"},
            {"name": "7:30am - 8:00am", "value": "07:30_08:00"},
            {"name": "8:00am - 8:30am", "value": "08:00_08:30"},
            {"name": "8:30am - 9:00am", "value": "08:30_09:00"},
            {"name": "9:00am - 9:30am", "value": "09:00_09:30"},
            {"name": "9:30am - 10:00am", "value": "09:30_10:00"},
            {"name": "10:00am - 10:30am", "value": "10:00_10:30"},
            {"name": "10:30am - 11:00am", "value": "10:30_11:00"},
            {"name": "11:00am - 11:30am", "value": "11:00_11:30"},
            {"name": "11:30am - 12:00pm", "value": "11:30_12:00"},
            {"name": "12:00pm - 12:30pm", "value": "12:00_12:30"},
            {"name": "12:30pm - 1:00pm", "value": "12:30_13:00"},
            {"name": "1:00pm - 1:30pm", "value": "13:00_13:30"},
            {"name": "1:30pm - 2:00pm", "value": "13:30_14:00"},
            {"name": "2:00pm - 2:30pm", "value": "14:00_14:30"},
            {"name": "2:30pm - 3:00pm", "value": "14:30_15:00"},
            {"name": "3:00pm - 3:30pm", "value": "15:00_15:30"},
            {"name": "3:30pm - 4:00pm", "value": "15:30_16:00"},
            {"name": "4:00pm - 4:30pm", "value": "16:00_16:30"},
            {"name": "4:30pm - 5:00pm", "value": "16:30_17:00"},
            {"name": "5:00pm - 5:30pm", "value": "17:00_17:30"},
            {"name": "5:30pm - 6:00pm", "value": "17:30_18:00"},
            {"name": "6:00pm - 6:30pm", "value": "18:00_18:30"},
            {"name": "6:30pm - 7:00pm", "value": "18:30_19:00"},
            {"name": "7:00pm - 7:30pm", "value": "19:00_19:30"},
            {"name": "7:30pm - 8:00pm", "value": "19:30_20:00"},
            {"name": "8:00pm - 8:30pm", "value": "20:00_20:30"},
            {"name": "8:30pm - 9:00pm", "value": "20:30_21:00"},
            {"name": "9:00pm - 9:30pm", "value": "21:00_21:30"},
            {"name": "9:30pm - 10:00pm", "value": "21:30_22:00"},
            {"name": "10:00pm - 10:30pm", "value": "22:00_22:30"},
            {"name": "10:30pm - 11:00pm", "value": "22:30_23:00"}
        ]        
    }
    return [
        {"name": "6:00am - 7:00am", "value": "06:00_07:00"},
        {"name": "7:00am - 8:00am", "value": "07:00_08:00"},
        {"name": "8:00am - 9:00am", "value": "08:00_09:00"},
        {"name": "9:00am - 10:00am", "value": "09:00_10:00"},
        {"name": "10:00am - 11:00am", "value": "10:00_11:00"},
        {"name": "11:00am - 12:00pm", "value": "11:00_12:00"},
        {"name": "12:00pm - 1:00pm", "value": "12:00_13:00"},
        {"name": "1:00pm - 2:00pm", "value": "13:00_14:00"},
        {"name": "2:00pm - 3:00pm", "value": "14:00_15:00"},
        {"name": "3:00pm - 4:00pm", "value": "15:00_16:00"},
        {"name": "4:00pm - 5:00pm", "value": "16:00_17:00"},
        {"name": "5:00pm - 6:00pm", "value": "17:00_18:00"},
        {"name": "6:00pm - 7:00pm", "value": "18:00_19:00"},
        {"name": "7:00pm - 8:00pm", "value": "19:00_20:00"},
        {"name": "8:00pm - 9:00pm", "value": "20:00_21:00"},
        {"name": "9:00pm - 10:00pm", "value": "21:00_22:00"},
        {"name": "10:00pm - 11:00pm", "value": "22:00_23:00"}
    ]    
}

export const getRequestedTime = (valueString, requestedDate, shift, interval) => {
    const date = new Date();
    let hour = date.getHours();
    let hourWithShift = new Date(date.getTime() + (shift || 0)*60000).getHours() + 1;
    const date1 = new Date(date.getTime());
    date1.setHours(0,0,0,0);
    const date2 = new Date(requestedDate.getTime());
    date2.setHours(0,0,0,0);
    const isToday = date1.getTime() === date2.getTime();

    const result = getDeliveryRequestedTimeOptions(interval).filter(v => {
        if ((valueString || "").includes(v.value)) {
            if (isToday) {
                let hourCurrent = v.value.split(":")[0];
                if (hourCurrent.startsWith("0")) {
                    hourCurrent = hourCurrent.replace("0", "");
                }
                let minutesCurrent = (v.value.split(":")[1] || "").split("_")[0];
                if (minutesCurrent.startsWith("0")) {
                    minutesCurrent = minutesCurrent.replace("0", "");
                }
                if (hourWithShift > hourCurrent) {
                    return false;
                }
            }
            return true;
        }
        return false;
    });

    return result;
};


export const getMinDate = (shift, timeOptionList, interval) => {
    const now = new Date();
    const nowWithShift = new Date(now.getTime() + (shift || 0)*60000);
    if (now.getDay() !== nowWithShift.getDay()) {
        return nowWithShift;
    }
    if (timeOptionList) {
        const tomorrow = new Date(now);
        tomorrow.setDate(tomorrow.getDate() + 1);
        const requestedTimeList = getRequestedTime(timeOptionList, new Date(), shift, interval);
        if (requestedTimeList.length === 0) {
            return tomorrow;
        }
    }
    return now;
};
